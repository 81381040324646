import * as React from 'react'
import { graphql } from 'gatsby'
import { filter } from 'lodash'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SectionTemplates from '../components/sectionTemplates'

const IndexPage = ({ data }) => {
  const frameworks = filter(
    data.allFile.nodes,
    (node) => node.sourceInstanceName === 'filters' && node.childMdx.frontmatter.isPrimary
  )

  return (
    <Layout>
      <Seo title="Tailwind templates / Bulma templates" />
      {frameworks.map((node) => (
        <SectionTemplates
          key={node.id}
          title={node.childMdx.frontmatter.title}
          subtitle={node.childMdx.frontmatter.subtitle}
          tags={node.childMdx.frontmatter.tags}
          nodes={filter(
            data.allFile.nodes,
            (subNode) =>
              subNode.sourceInstanceName === 'templates' &&
              subNode.childMdx.frontmatter.filter === node.childMdx.slug
          )}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { in: ["filters", "templates"] }
        extension: { eq: "mdx" }
        childMdx: { frontmatter: { isArchived: { ne: true }, isHiddenHome: { ne: true } } }
      }
      sort: { fields: childMdx___frontmatter___order }
    ) {
      nodes {
        id
        sourceInstanceName
        childMdx {
          slug
          frontmatter {
            filter
            slug
            name
            tech
            title
            subtitle
            subtitle2
            longTitle
            listTitle
            icons
            tags
            isFree
            isPrimary
            updated(formatString: "MMMM D, YYYY")
            screenshot {
              childImageSharp {
                gatsbyImageData
              }
            }
            screenshots {
              childImageSharp {
                gatsbyImageData
                id
              }
            }
            hasMultipleColors
          }
        }
      }
    }
  }
`

export default IndexPage
