import * as React from 'react'
import TemplateCard from './templateCard'
import TemplateCardWrapper from './templateCardWrapper'
import TemplateTitle from './title/templateTitle'
import TemplateCardMultipleImages from './templateCard/multipleImages'

const TemplateCardWide = ({
  item,
  image,
  alt,
  isFree = true,
  to,
  href,
  linkTitle,
  className = '',
}) => {
  return (
    <TemplateCardWrapper
      to={to}
      href={href}
      premium={!isFree}
      className={`${className} bg-white flex-col lg:flex-row justify-between items-center hover:shadow-xl transition-shadow`}
      title={linkTitle}
    >
      <div className={`lg:mr-6 mb-6 lg:mb-0`}>
        <TemplateCard image={image} alt={alt} padding={1} className={`md:w-96 h-auto bg-gray-50`} />

        <TemplateCardMultipleImages item={item} isInList={true} />
      </div>

      <TemplateTitle className="flex-1" item={item} />
    </TemplateCardWrapper>
  )
}

export default TemplateCardWide
