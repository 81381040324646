import * as React from 'react'
import SectionDefault from './sectionDefault'
import TemplateCardWide from './templateCardWide'
import SectionTitle from './sectionTitle'
// import { useStaticQuery, graphql } from 'gatsby'
import { templatePath } from '../helpers'

const SectionTemplates = ({ nodes, title, subtitle, tags }) => {
  return (
    <>
      <SectionTitle title={title} subtitle={subtitle} tags={tags} isLarger={true} />
      <SectionDefault className={`space-y-12`} maxW={`max-w-6xl`}>
        {nodes.map(({ childMdx: { frontmatter: item }, ...node }) => (
          <TemplateCardWide
            key={node.id}
            item={item}
            image={item.screenshot}
            alt={item.longTitle}
            isFree={item.isFree}
            to={templatePath(item)}
            linkTitle={item.longTitle}
          />
        ))}
      </SectionDefault>
    </>
  )
}

export default SectionTemplates
